import { reactive } from "vue";

const theme = reactive({
    agentLogo: "Footer.png",
    devLogo: "Header.png",
    apiUrl: "https://api.metaluxe.com.au/",
    bunnyApiUrl: "https://api.bunny.net/",
    accessKey: "07aeb6d1-92b0-48fc-9fa3-e202bc32d8753a016979-9f0b-4b1d-8027-5575c13bad47",
    streamToken: "4afa209c-7c4a-43eb-b482-e90156846a46",
    videoLibraryId: "132164",
    streamCdnUrl: "https://vz-d5a46c0c-876.b-cdn.net",
    streamApiKey: "6f786303-d37a-4878-b61686111780-5c46-42e2",
    storageApiPassword: "159899be-4b6a-4189-8fc935bf1cc9-8d1c-4ae4",
    cdnUrl: "https://harbour-shores-pwa.b-cdn.net",
    devFolder: "/harbour-shores-pwa",
    googleAnalytics: "",
    videosIcon: "/img/ui-theme/videos-icon.svg",
    rendersIcon: "/img/ui-theme/renders-icon.svg",
    rangeIcon: "/img/ui-theme/range-icon.svg",
    panoramasIcon: "/img/ui-theme/panoramas-icon.svg",
    floorplansIcon: "/img/ui-theme/floorplans-icon.svg",
    dashboardMp4: "theme/dashboard/4K_dashboard_dup.mp4",

    // top level menu
    assetsMenuOrder: [
        'master-plan-flythrough',
        'balcony-views',
        'apartment-f2',
        'apartment-b2',
        'apartment-a10',
        'apartment-c5',
    ],

    bed1MenuOrder: [
        'videos',
        'virtual-tour',
        'balcony-view',
        'floorplan',
        'range',
        'renders',
        'pdf'
    ],

    bed1Asset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [
            'Harbour Shores Demo'
        ],
        panoramasUrl: [
            'https://app.cloudpano.com/tours/lmYmvNs3h'
        ],
        // balcony: [0, 1, 2],
        balcony: [0, 1],
        balconyName: [
            'The Waterline',
            // 'Palm House',
            'The Residences'
        ],
        balconyUrl: [
            'https://storage.net-fs.com/hosting/7566552/20/',
            // 'https://storage.net-fs.com/hosting/7566552/12/',
            'https://storage.net-fs.com/hosting/7566552/13/'
        ],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
    },

    bed2Asset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [
            'Apartment F2'
        ],
        panoramasUrl: [
            'https://storage.net-fs.com/hosting/7566552/16/'
        ],
        balcony: [0],
        balconyName: [],
        balconyUrl: [],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [0],
        floorplansName: ['Apartment 2'],
        floorplansUrl: ['https://harbour-shores-pwa.b-cdn.net/assets/apartment-f2/floorplan/WF2.pdf'],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
    },

    bed3Asset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [
            'Apartment B2'
        ],
        panoramasUrl: [
            'https://storage.net-fs.com/hosting/7566552/15/'
        ],
        balcony: [0],
        balconyName: [
        ],
        balconyUrl: [
        ],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [0],
        floorplansName: [
            'Apartment B2',
        ],
        floorplansUrl: [
            'https://harbour-shores-pwa.b-cdn.net/assets/apartment-b2/floorplan/WB2.pdf',
        ],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
    },

    bed4Asset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [
            'Apartment A10'
        ],
        panoramasUrl: [
            'https://storage.net-fs.com/hosting/7566552/14/'
        ],
        balcony: [0],
        balconyName: [],
        balconyUrl: [],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [0],
        floorplansName: ['Apartment A10'],
        floorplansUrl: ['https://harbour-shores-pwa.b-cdn.net/assets/apartment-a10/floorplan/WA10.pdf'],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
    },

    bed5Asset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [
            'Apartment C5'
        ],
        panoramasUrl: [
            'https://storage.net-fs.com/hosting/7566552/17/'
        ],
        balcony: [0],
        balconyName: [],
        balconyUrl: [],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [0],
        floorplansName: ['Apartment C5'],
        floorplansUrl: ['https://harbour-shores-pwa.b-cdn.net/assets/apartment-c5/floorplan/WC5.pdf'],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
    },

    bed6Asset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [],
        panoramasUrl: [],
        balcony: [0],
        balconyName: [],
        balconyUrl: [],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
    },
});

export default { theme };
